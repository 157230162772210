@import "src/main/scss/base/grid";

@mixin mq($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($grid-breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($grid-breakpoints, $breakpoint);
    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }
  // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}
