@import
"src/main/scss/mixins/media-query",
"src/main/scss/mixins/state-indicator",
"src/main/scss/functions/find-color-invert";

.card-outer {
  margin-bottom: var(--space-xl);

  &:not(:last-child){
    margin-bottom: var(--space-md);
  }

  .card {
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    //background: #fff;
    //margin-bottom: var(--space-md);

    &:not(:last-child){
      margin-bottom: var(--space-xl);
    }

    &__media {
      display: flex;
      flex: 0 0 auto;
      //margin-right: 20px;
    }

    &__body {
      flex-grow: 1;
    }

    &__content {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      //justify-content: space-between;
    }

    &__inline-block {
      display: block;
      margin-bottom: calc(var(--space-md)*var(--text-vspace-multiplier));

      p,
      a {
        display: inline;
      }

    }

    &__clickarea {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      z-index: 2;
      outline: 0;
    }

    &__header {
      display: flex;
      align-items: center;
      margin-bottom: var(--space-md);
    }

    &__headline {
      position: relative;
      display: flex;
      align-items: center;
      margin: 0;
    }

    &__icon {
      width: 50px;
      @include mq(md) {
        width: 60px;
      }

      &.is {
        &--width-40 {
          width: 40px;
        }
        &--width-60 {
          width: 60px;
        }
        &--width-80 {
          width: 80px;
        }
        &--width-100 {
          width: 100px;
        }
        &--width-120 {
          width: 120px;
        }
        &--width-140 {
          width: 140px;
        }
        &--height-40 {
          height: 40px;
        }
        &--height-60 {
          height: 60px;
        }
        &--height-80 {
          height: 80px;
        }
        &--height-100 {
          height: 100px;
        }
        &--height-120 {
          height: 120px;
        }
        &--height-140 {
          height: 140px;
        }
      }
    }

    &__body {
      &>:first-child {
        margin-top: 0;
      }
    }

    &__footer {
      position: relative;
      margin-top: var(--space-md);
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    &__buttons {
      .btn {
        &:not(:last-child){
          margin-right: var(--space-sm);
        }
      }
    }

    &__icons {
      .btn {
        &:not(:last-child){
          margin-right: var(--space-md);
        }
      }
    }

    &__seperator {
      display: block;
      width: 100%;
      height: 1px;
      background-color: var(--color-border);
    }

    &__component {
      width: 100%;
      //background: var(--color-bg-dark);
      //border-top: 1px solid rgba(255,255,255,.05);
    }
  }

  &.is {
    &--grid {
      display: flex;
      flex-wrap: wrap;
      @include mq(lg) {
        justify-content: center;
      }

      .card {
        &__grid {
          margin-bottom: 30px;
          display: flex;
          flex-wrap: wrap;
          @include mq(md) {
            // 2 per row
            flex: 0 0 calc((100% - 60px) / 2);
            margin: 0 15px 30px;
          }

          @include mq(lg) {
            // 3 per row
            flex: 0 0 calc((100% - 90px) / 3);
          }



          // @include mq(md) {
          //   display: flex;
          //   flex: 1 0 50%;
          // }
          //
          // &:nth-child(odd) {
          //   .card {
          //     @include mq(md) {
          //       margin: 0 var(--space-lg) var(--space-lg) 0;
          //     }
          //   }
          // }
          // &:nth-child(even) {
          //   .card {
          //     @include mq(md) {
          //       margin: 0 0 var(--space-lg) var(--space-lg);
          //     }
          //   }
          // }
          //

          .card {
          //   margin-bottom: var(--space-lg);
          //   @include mq(md) {
          //     flex: 1 1 auto;
          //   }
          }
        }
      }
    }

    &--curved {
      .card {
        @include mq(md) {
          max-width: var(--container-xs-max-widths);
          width: auto;
        }
        @include mq(lg) {
          max-width: 70%;
        }

        &:nth-child(1),
        &:nth-child(4) {
          @include mq(lg) {
            margin-left: 100px;
          }

        }
      }
    }
  }
  /* Modifiers */
  .is {
    &--bg-color {
      background: rgba(255,255,255,.05);
    }
    // Variants
    &--outline {
      background: var(--color-bg);
      border: 2px solid var(--color-border);
      border-radius: 5px;
      overflow: visible;

      &:before {
        content: '';
        pointer-events: none;
        position: absolute;
        border-radius: inherit;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        z-index: 0;
        border: 2px solid var(--color-accent);
        box-shadow: 0 3px 20px var(--color-accent);
        opacity: 0;
        transition: opacity .6s cubic-bezier(0.0, 0.0, 0.2, 1);
      }

      &:hover {
        &:before {
          opacity: 1;
        }
      }

    }
    // Primary
    &--primary {
      // TODO: Der Mixin <text-contrast> muss mit CSS Vars funktionieren!
      background: #234360;
      $color-primary: red;

      *:not(.btn) {
        // Could also have alpha value: @include text-contrast($color-primary, .5);
        @include text-contrast($color-primary);
      }
    }
  }
}
